

export const createCategory = (userId, token, category) => {
    return fetch(`/category/create/${userId}`, {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             Authorization: `Bearer ${token}`
         },
         body: category
     })
     .then(response => {
         return response.json()
     })
     .catch(err => {
         console.log(err)
     })
 }

 export const deleteCategory = (userId, token,id) => {
    return fetch(`/category/${userId}`, {
        method: 'DELETE',
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({id})
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => console.log(err));
}

 export const getCategories = () => {
    return fetch(`/categories`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => console.log(err));
};


export const getCategory = (id) => {
    return fetch(`/category`,{
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify({id})
    })
    .then(response => {
        return response.json();  
    })
    .catch(err => console.log(err));
}


export const updateCategory = (category) => {
    return fetch(`/category/update`, {
         method: 'POST',
         headers: {
             Accept: 'application/json'
         },
         body: category
     })
     .then(response => {
         return response.json()
     })
     .catch(err => {
        return err.json()
     })
 }
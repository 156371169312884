

export const getBookingTimesDefault = () => {
    return fetch(`/bookings/getbookingtimes`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {console.log(err)});
}

export const setBookingTimesDefault = (userId, token, data) => {
    return fetch(`/bookings/setcustombookingtimes/${userId}`, {
        method: 'PUT',
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {console.log(err)});
}

export const getBookingTimesCustom = () => {
    return fetch(`/bookings/getcustombookingtimes`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {console.log(err)});
}

export const getBookingTimesAll = () => {
    return fetch(`/bookings/all`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {console.log(err)});
}
export const deleteBookingsTimes = (id) => {
    return fetch(`/bookings/delete`, {
        method: 'DELETE',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify({id})
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => console.log(err));
}

export const setHoliday = (data) => {
    return fetch(`/month/holiday`, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {});
}

export const getWorkingDays = (data) => {
    return fetch(`/days/read`, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {console.log(err)});
}

export const setWorkingDay = (data) => {
    return fetch(`/days/update`, {
        method: 'PUT',
        headers: {
            "Content-type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {console.log(err)});
}



export const setAdminInfoApi = (userId, token, data) => {
    return fetch(`/about/create/${userId}`, {
        method: 'POST',
        headers: {
           Accept: "application/json",
           Authorization: `Bearer ${token}`
       },
        body: data
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err)
    })
}

export const getAdminInfo = () => {
    return fetch(`/aboutapi`, {
        method: 'GET'
   ,
    })
        .then(response => {
            return response.json();  
        })
        .catch(err => {console.log(err)});
}